<template>
	<div class="mtipomanipulacionFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipomanipulacion.idtipoman')" :value="getIdtipomanValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipomanipulacion.codmanedi')" :value="getCodmanediValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipomanipulacion.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mtipomanipulacion-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mtipomanipulacion'
		};
	},
	computed: {
		getIdtipomanValue() {
			return this.model && this.model.idtipoman ? this.model.idtipoman : '-';
		},
		getCodmanediValue() {
			return this.model && this.model.codmanedi ? this.model.codmanedi : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
